import { useState } from 'react'
import './style.css'

const ResultsItem = ({ giver, receiver, antiCheat, setPopin }) => {
    const [view, setView] = useState(0)

    return (
        <div className="resultsItem">
            <button onClick={() => {
                setPopin(receiver)
                setView(view + 1)
            }}>
                { giver }
            </button>

            { antiCheat ? <p>Vu { view } fois</p> : '' }
        </div>
    )
}

export default ResultsItem

import ResultsItem from '../ResultsItem'
import './style.css'

const ResultsList = ({ results, antiCheat, setPopin }) => (
    <div className="resultsList">
        { Object.entries(results).map(([giver, receiver]) => <ResultsItem key={ giver } giver={ giver } receiver={ receiver } antiCheat={ antiCheat } setPopin={ setPopin } />) }
    </div>
)

export default ResultsList

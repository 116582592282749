import Generate from '../Generate'
import './style.css'

const Controls = ({ step, setStep, participants, exclusions, setExclusions, setResults }) => (
    <div className="controls">
        { step === 1 ? (
            <button type="button" onClick={() => setStep(2)}>
                Suivant
            </button>
        ) : '' }

        { step === 2 ? (
            <>
                <button type="button" onClick={() => {
                    setStep(1)
                    setExclusions({})
                }}>
                    Précédent
                </button>

                <Generate
                    participants={ participants }
                    exclusions={ exclusions }
                    setResults={ setResults }
                    setStep={ setStep }
                />
            </>
        ) : '' }

        { step === 3 ? (
            <button type="button" onClick={() => window.location.reload()}>
                Recommencer
            </button>
        ) : '' }
    </div>
)

export default Controls

import { toast } from 'react-toastify'

const Generate = ({ participants, exclusions, setResults, setStep }) => {
    const shuffle = (array) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }

        return array
    }

    const isValidAssignment = (names, shuffledNames) => {
        for (let i = 0; i < names.length; i++) {
            if (names[i] === shuffledNames[i]) return false;
            if (exclusions[names[i]] && exclusions[names[i]].includes(shuffledNames[i])) return false;
        }
        return true
    }

    const generateResults = () => {
        const names = participants.filter(name => name.trim() !== "")

        if (names.length < 2) {
            toast.error("Il doit y avoir au moins deux participants valides pour procéder au tirage au sort.")
            setResults([])
            return
        }

        let shuffledNames
        let attempts = 0
        const maxAttempts = 1000

        do {
            shuffledNames = shuffle([...names])
            attempts++
        } while (!isValidAssignment(names, shuffledNames) && attempts < maxAttempts)

        if (attempts === maxAttempts) {
            toast.error("Impossible de procéder au tirage au sort avec les exclusions fournies.")
            setResults({})
        } else {
            const result = {}
            names.forEach((name, index) => {
                result[name] = shuffledNames[index]
            })
            setResults(result)
            setStep(3)
        }
    }

    return (
        <button className="generate" type="button" onClick={() => generateResults()}>
            Tirage au sort
        </button>
    )
}

export default Generate

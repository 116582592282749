import ParticipantInput from '../components/ParticipantInput'
import ParticipantAdd from '../components/ParticipantAdd'

const Participants = ({ participants, setParticipants, setStep }) => (
    <main>
        { participants.map((name, index) => (
            <ParticipantInput
                key={index}
                index={ index }
                name={ name }
                participants={ participants }
                setParticipants={ setParticipants }
            />
        )) }

        <ParticipantAdd
            participants={ participants }
            setParticipants={ setParticipants }
        />
    </main>
)

export default Participants

import './style.css'

const sentences = {
    1: "Pour commencer, qui participe ?",
    2: "Choisis les exclusions si quelqu'un ne veut pas tomber sur une personne.",
    3: "Clique sur ton nom pour connaitre ton Secret Brocante, ferme la bulle d'information et passe à ton voisin."
}

const Information = ({ step }) => (
    <p className="information">
        { sentences[step] || '' }
    </p>
)

export default Information

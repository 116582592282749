import { useState } from 'react'
import AntiCheat from '../components/AntiCheat'
import ResultsList from '../components/ResultsList'
import ResultsPopin from '../components/ResultsPopin'

const Results = ({ results }) => {
    const [antiCheat, setAntiCheat] = useState(false)
    const [popin, setPopin] = useState('')

    return (
        <main>
            <AntiCheat antiCheat={ antiCheat } setAntiCheat={ setAntiCheat } />

            <ResultsList results={ results } antiCheat={ antiCheat } setPopin={ setPopin } />

            <ResultsPopin popin={ popin } setPopin={ setPopin } />
        </main>
    )
}

export default Results

import './style.css'

const ParticipantInput = ({ index, name, participants, setParticipants }) => {
    const handleNameChange = (index, newName) => {
        const newParticipants = [...participants]

        newParticipants[index] = newName
        setParticipants(newParticipants)
    }

    const removeInput = (index) => {
        const newParticipants = [...participants]

        newParticipants.splice(index, 1)
        setParticipants(newParticipants)
    }

    return (
        <div className="participantInput">
            <input
                type="text"
                value={name}
                placeholder={`Prénom n°${index + 1}`}
                onChange={(e) => handleNameChange(index, e.target.value)}
            />

            { index > 1 ? (
                <button
                    type="button"
                    onClick={() => removeInput(index)}
                >
                    <svg strokeWidth="0" viewBox="0 0 16 16" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"></path><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"></path></svg>
                </button>
            ) : '' }
        </div>
    )
}

export default ParticipantInput

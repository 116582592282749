import { useState } from 'react'
import { ToastContainer } from 'react-toastify'
import Information from './components/Information'
import Controls from './components/Controls'
import Participants from './pages/Participants'
import Exclusions from './pages/Exclusions'
import Results from './pages/Results'
import 'react-toastify/dist/ReactToastify.css'
import './assets/normalize.css'
import './App.css'

const App = () => {
    const [step, setStep] = useState(1)
    const [participants, setParticipants] = useState(['', ''])
    const [exclusions, setExclusions] = useState({})
    const [results, setResults] = useState({})

    const steps = {
        1: <Participants participants={ participants } setParticipants={ setParticipants } setStep={ setStep } />,
        2: <Exclusions participants={ [...new Set(participants.filter(item => item !== ''))] } exclusions={ exclusions } setExclusions={ setExclusions } />,
        3: <Results results={ results } />
    }

    return (
        <div className="app">
            <h1>(secret"brocante)</h1>

            <Information step={ step } />

            { steps[step] }

            <Controls step={ step } setStep={ setStep } participants={ participants } exclusions={ exclusions } setExclusions={ setExclusions } setResults={ setResults } />

            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
            />
        </div>
    )
}

export default App

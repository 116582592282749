import ExclusionsItem from '../components/ExclusionsItem'

const Exclusions = ({ participants, exclusions, setExclusions }) => (
    <main>
        { participants.length > 1 ? participants.map((participant, index) => (
            <ExclusionsItem
                key={ index }
                participant={ participant }
                participants={ participants }
                exclusions={ exclusions }
                setExclusions={ setExclusions }
            />
        )) : (
            <p className="emptyMessage">
                Pas assez de participants.
            </p>
        ) }
    </main>
)

export default Exclusions

import './style.css'

const ResultsPopin = ({ popin, setPopin }) => {
    return (
        <div className={`resultsPopin ${ popin ? 'is-active' : '' }`}>
            <div>
                <button onClick={() => setPopin('')} disabled={ popin ? false : true }>
                    x
                </button>

                <strong>&nbsp; { popin } &nbsp;</strong>
                <p>est votre secret brocante</p>
            </div>
        </div>
    )
}

export default ResultsPopin

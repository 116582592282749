import './style.css'

const AntiCheat = ({ antiCheat, setAntiCheat }) => (
    <button className={`antiCheat ${antiCheat ? 'is-active' : ''}`} onClick={() => setAntiCheat(!antiCheat)}>
        <span />
        Anti-triche
    </button>
)

export default AntiCheat

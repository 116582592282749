import './style.css'

const ExclusionsItem = ({ participant, participants, exclusions, setExclusions }) => {
    const handleExclusionChange = (participant, excludedParticipant, isChecked) => {
        setExclusions(prevExclusions => {
            const newExclusions = { ...prevExclusions }

            if (isChecked) {
                if (!newExclusions[participant]) {
                    newExclusions[participant] = []
                }

                newExclusions[participant].push(excludedParticipant)
            } else {
                newExclusions[participant] = newExclusions[participant].filter(p => p !== excludedParticipant)

                if (newExclusions[participant].length === 0) {
                    delete newExclusions[participant]
                }
            }

            return newExclusions
        })
    }

    return (
        <div className="exclusionsItem">
            <p>{ participant }&nbsp;:</p>

            <ul>
                { participants.filter(p => p !== participant).map(otherParticipant => (
                    <li key={otherParticipant}>
                        <input
                            type="checkbox"
                            id={`${participant}-${otherParticipant}`}
                            checked={exclusions[participant] && exclusions[participant].includes(otherParticipant)}
                            onChange={(e) => handleExclusionChange(participant, otherParticipant, e.target.checked)}
                        />

                        <label htmlFor={`${participant}-${otherParticipant}`}>{otherParticipant}</label>
                    </li>
                )) }
            </ul>
        </div>
    )
}

export default ExclusionsItem
